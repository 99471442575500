/**@jsx jsx*/
import React, { useReducer, useMemo } from "react"
import { useGlobalState, useGlobalDispatch } from "../utilities/globalContext"
import { useWillMount } from "../utilities/lifecycleHooks"
import { navigate } from "gatsby"
import nprogress from "nprogress"

import styled from "@emotion/styled"
import Layout from "../components/layout"
import FloatInput from "../components/floatInput"

import { TitlePrimary, TitleSecondary } from "../components/titles"
import { css, jsx } from "@emotion/core"
import axios from "axios"
import { Col } from "boostly-ui2"
import { ButtonPrimary } from "../components/buttons"
import { serverURL } from "../../variables"
import { largeMT, medMT } from "../components/spacing"

nprogress.configure({ minimum: 0.1, trickleSpeed: 200, parent: `#progParent` })
const loginURL = `${serverURL}/api/users/login`

const Warning = styled.div`
  color: tomato;
`
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_EMAIL":
      return {
        ...state,
        email: action.email,
        allowSubmit: action.email && state.password,
      }
    case "SET_PASSWORD":
      return {
        ...state,
        password: action.password,
        allowSubmit: action.password && state.email,
      }
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.message,
      }
    default:
      return state
  }
}

const Input = styled(FloatInput)`
  width: 90%;
  max-width: 500px;
`

const Login = () => {
  const [state, dispatch] = useReducer(reducer, {
    email: "",
    password: "",
    allowSubmit: false,
    message: "",
  })
  const globalDispatch = useGlobalDispatch()
  const { user } = useGlobalState()

  useWillMount(() => {
    user && navigate("/dashboard")
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    if (state.allowSubmit) {
      const { email, password } = state
      try {
        nprogress.start()
        console.log(loginURL)
        let res = await axios.post(loginURL, {
          email: email,
          password: password,
        })
        console.log(res.data)
        const user = res.data
        globalDispatch({ type: "SET_USER", user: user })
        nprogress.done()
        navigate("/dashboard")
      } catch (e) {
        nprogress.done()
        dispatch({
          type: "SET_MESSAGE",
          message: e.response && e.response.data.message,
        })
      }
    } else {
      dispatch({
        type: "SET_MESSAGE",
        message: "All fields need to be filled.",
      })
    }
  }

  const handleEmailChange = e => {
    dispatch({ type: "SET_EMAIL", email: e.target.value })
  }

  const handlePasswordChange = e => {
    dispatch({ type: "SET_PASSWORD", password: e.target.value })
  }

  const emailInput = useMemo(
    () => (
      <Input
        key="dontRerender1"
        type="text"
        placeholder="email"
        onChange={handleEmailChange}
        css={largeMT}
      />
    ),
    []
  )
  const passwordInput = useMemo(
    () => (
      <Input
        key="dontRerender2"
        type="password"
        placeholder="password"
        onChange={handlePasswordChange}
        css={largeMT}
      />
    ),
    []
  )

  return (
    <Layout id="progParent">
      <form
        css={css`
          z-index: 2;
          grid-column-start: 2;
          grid-row-start: 2;
          padding-top: 40px;
          @media (max-width: 500px) {
            padding-top: 10px;
          }
        `}
        onSubmit={handleSubmit}
      >
        <Col
          css={css`
            height: 250px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          `}
          x
          space="between"
        >
          <TitlePrimary css={medMT}>We missed you.</TitlePrimary>
          {emailInput}
          {passwordInput}
          <Warning>{state.message && state.message}</Warning>
          <ButtonPrimary css={largeMT}>Log In</ButtonPrimary>
        </Col>
      </form>
    </Layout>
  )
}

export default Login
